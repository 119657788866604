<template>
    <div class="game-admin">
        <h1>Game Management</h1>

        <!-- Search Bar -->
        <div class="search-container">
            <input v-model="searchQuery" @input="searchGames" type="text" placeholder="Search Games..." />
        </div>

        <!-- Games Table -->
        <table v-if="filteredGames.length > 0" class="games-table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Game Name</th>
                    <th>Provider</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Featured</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(game, index) in filteredGames" :key="index">
    <td v-if="game">{{ game.id }}</td>
    <td v-if="game">{{ game.game_name }}</td>
    <td v-if="game">{{ game.provider_game }}</td>
    <td v-if="game">{{ game.game_type }}</td>
    <td v-if="game">{{ game.status === 1 ? 'Active' : 'Inactive' }}</td>
    <td v-if="game">{{ game.featured === 1 ? 'Yes' : 'No' }}</td>
    <td v-if="game">
        <button @click="editGame(game)">Edit</button>
        <button @click="deleteGame(game.id)">Delete</button>
    </td>
</tr>
            </tbody>
        </table>

        <!-- No Results Found -->
        <div v-if="filteredGames.length === 0" class="no-results">
            Nothing found
        </div>

        <!-- See More Button -->
        <button v-if="showSeeMore" @click="loadMoreGames">See More</button>

        <!-- Edit Game Modal -->
        <div v-if="showEditModal" class="modal">
    <div class="modal-content">
        <h2>Edit Game</h2>
        <div v-if="loading">Loading...</div>
        <div v-else>
            <label>Game Name:</label>
            <input v-model="selectedGame.game_name" type="text" />
            
            <label>Provider:</label>
            <input v-model="selectedGame.provider_game" type="text" />
            
            <label>Type:</label>
            <input v-model="selectedGame.game_type" type="text" />

            <label>Status:</label>
            <select v-model="selectedGame.status">
                <option value="1">Active</option>
                <option value="0">Inactive</option>
            </select>

            <label>Featured:</label>
            <select v-model="selectedGame.featured">
                <option value="1">Yes</option>
                <option value="0">No</option>
            </select>
         

            <button @click="saveGame">Save</button>
            <button @click="closeEditModal">Cancel</button>
        </div>
        <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
    </div>
</div>
</div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
    
    return {
        games: [],
        filteredGames: [],
        searchQuery: '',
        showEditModal: false,
        selectedGame: null,
        limit: 50,
        skip: 0,
        showSeeMore: true,
        loading: false, // Loading durumu için state
        successMessage: '', // İşlem tamamlandı mesajı için state
       
    };
},
    mounted() {
        this.fetchGames();
    },
    methods: {
    // Sunucudan oyunları çeker
    async fetchGames() {
        try {
            const response = await axios.get('/admin/rivo/games', {
                params: {
                    limit: this.limit,
                    skip: this.skip,
                    search: this.searchQuery // Arama sorgusunu backend'e gönder
                }
            });
            if (response.data.length < this.limit) {
                this.showSeeMore = false; // Daha fazla oyun yoksa "See More" butonunu gizle
            }
            this.games = this.games.concat(response.data);
            this.filteredGames = this.games;
            this.skip += this.limit; // Sonraki yükleme için skip'i artır
        } catch (error) {
            console.error("Error fetching games:", error);
        }
    },
    
    // Arama sorgusuna göre oyunları MongoDB'de arar
    async searchGames() {
    try {
        const response = await axios.get('/games/search', {
            params: { search: this.searchQuery }
        });
        this.filteredGames = response.data;

        // Arama sonuçlarını ana `games` dizisine de kaydet
        this.games = response.data;
    } catch (error) {
        console.error("Error searching games:", error);
    }
},

    // Daha fazla oyun yükler
    loadMoreGames() {
        this.fetchGames();
    },

    // Seçili oyunu düzenlemek için modal'ı açar
    editGame(game) {
    if (game && game.id) {
        this.selectedGame = { ...game };
        this.showEditModal = true;
    } else {
        console.error("Selected game is null or does not have an ID.");
    }
},

    // Oyunu kaydeder
    async saveGame() {
    if (!this.selectedGame || !this.selectedGame.id) {
        console.error("Selected game is null or does not have an ID.");
        return;
    }
    
    this.loading = true; // Yükleniyor durumunu başlat
    this.successMessage = ''; // Önceki başarı mesajını temizle

    try {
        const response = await axios.put(`/admin/rivo/games/${this.selectedGame.id}`, this.selectedGame);
        
        // Hem `games` hem de `filteredGames` listelerinde oyunu bul ve güncelle
        const updateGameInList = (list) => {
            const index = list.findIndex(game => game.id === this.selectedGame.id);
            if (index !== -1) {
                list.splice(index, 1, response.data);
            }
        };

        updateGameInList(this.games);
        updateGameInList(this.filteredGames);

        this.successMessage = 'Game updated successfully!'; // Başarı mesajı
        this.closeEditModal(); // Modalı kapat
    } catch (error) {
        console.error("Error saving game:", error);
    } finally {
        this.loading = false; // Yükleniyor durumunu bitir
        this.selectedGame = null; // Seçilen oyunu sıfırla
        this.fetchGames(); // Tüm oyunları yeniden yükle
    }
},


editGame(game) {
    if (game && game.id) {
        this.selectedGame = { ...game };
        this.showEditModal = true;

        // Arama sonucu ise searchGameEdit'i çağıracak
        this.isSearchResult = true; // Yeni bir state ekleyebiliriz
    } else {
        console.error("Selected game is null or does not have an ID.");
    }
},

    // Seçili oyunu siler
    async deleteGame(id) {
        try {
            await axios.delete(`/admin/rivo/games/${id}`);
            this.fetchGames(); // Silme işleminden sonra listeyi yeniler
        } catch (error) {
            console.error("Error deleting game:", error);
        }
    },

    // Edit modal'ını kapatır
   closeEditModal() {
    this.showEditModal = false;
    this.selectedGame = null;
    
}

}

};
</script>

<style scoped>
.game-admin {
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
    min-height: 100vh;
}

h1 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.search-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.search-container input {
    width: 100%;
    max-width: 600px;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 25px;
    font-size: 16px;
    transition: border-color 0.3s ease-in-out;
}

.search-container input:focus {
    border-color: #007bff;
    outline: none;
}

.games-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.games-table th, .games-table td {
    padding: 15px 20px;
    text-align: left;
    font-size: 14px;
}

.games-table th {
    background-color: #007bff;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
}

.games-table td {
    border-bottom: 1px solid #eee;
    color: #333;
    vertical-align: middle;
}

.games-table tr:last-child td {
    border-bottom: none;
}

.games-table tr:nth-child(even) {
    background-color: #f8f9fa;
}

.games-table tr:hover {
    background-color: #f1f1f1;
}

button {
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
    color: #fff;
}

button.edit-button {
    background-color: #007bff;
    color: #fff;
    margin-right: 10px;
}

button.delete-button {
    background-color: #dc3545;
    color: #fff;
}

button.load-more {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

button.load-more:hover {
    background-color: #0056b3;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
}

.modal-content h2 {
    margin-top: 0;
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.modal-content label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    color: #555;
}

.modal-content input, .modal-content select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 14px;
}

.modal-content button {
    width: 100%;
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.modal-content button:hover {
    background-color: #0056b3;
}

.modal-content .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #999;
}

.modal-content .close-button:hover {
    color: #333;
}

.no-results {
    text-align: center;
    font-size: 18px;
    color: #999;
    margin-top: 20px;
}

.modal-content .success-message {
    margin-top: 10px;
    color: green;
    font-weight: bold;
}

</style>
